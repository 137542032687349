body {
	font-family: Lato;
	font-size: 16px;
	font-weight: 400;
	margin: 0;
}

@supports not (-webkit-touch-callout: none) {
	/* CSS for other than iOS devices */
	body {
		height: 100vh;
	}
}

div {
	outline: unset !important;
}

button {
	font-family: Metropolis;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
}

h1 {
	font-family: Metropolis;
	font-size: 24px;
	font-weight: 500;
}

h2 {
	font-family: Metropolis;
	font-size: 20px;
	font-weight: 500;
}

.ButtonPrimary {
	background: #5c81ea;
	border-radius: 8px;
	color: #ffffff;
	border: 0;
	height: 42px;
}

.ButtonPrimary:hover {
	background: #7d9aee;
}

.ButtonPrimary:active {
	background: #486aca;
}

.ButtonPrimary:disabled {
	background: #ced9f9;
}

.ButtonOutlined {
	background: #ffffff;
	border: 1px solid #5c81ea;
	box-sizing: border-box;
	border-radius: 8px;
	color: #5c81ea;
	height: 42px;
}

.ButtonOutlined:hover {
	background: #eff2fd;
	border: 1px solid #5c81ea;
	box-sizing: border-box;
	border-radius: 8px;
}

.ButtonOutlined:active {
	background: #d6dffa;
	border: 1px solid #5c81ea;
	box-sizing: border-box;
	border-radius: 8px;
}

.ButtonOutlined:disabled {
	color: #ced9f9;
	background: #ffffff;
	border: 1px solid #ced9f9;
	box-sizing: border-box;
	border-radius: 8px;
}

.ButtonText {
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 8px;
	border: 0;
	color: #5c81ea;
	height: 42px;
}

.ButtonText:hover {
	background: #eff2fd;
}

.ButtonText:active {
	background: #d6dffa;
}

.ButtonText:disabled {
	background: #e5e5e5;
	color: #ced9f9;
}

.ButtonSecondary {
	color: #ffffff;
	background: #edbc0e;
	border-radius: 8px;
	border: 0;
	height: 42px;
}

.ButtonSecondary:hover {
	background: #f3d362;
}

.ButtonSecondary:active {
	background: #dda108;
}

.ButtonSecondary:disabled {
	background: #f9e7ab;
}

.ButtonAlert {
	color: #ffffff;
	background: #f55151;
	border-radius: 8px;
	border: 0;
	height: 42px;
}

.ButtonAlert:hover {
	background: #f55151;
}

.ButtonAlert:active {
	background: #f77474;
}

.ButtonAlert:disabled {
	background: #da4c4c;
}

.ButtonLarge {
	box-shadow: 0px 0px 4px rgba(92, 129, 234, 0.45);
	border-radius: 10px;
	background: #ffffff;
	font-family: Lato;
	font-weight: normal;
	font-size: 16px;
	border: 0;
	text-align: left;
	padding: 0;
}

.ButtonLarge:hover {
	background: #fdfdff;
	box-shadow: 0px 0px 6px rgba(92, 129, 234, 0.35);
}

.ButtonLarge:active {
	background: #f4f6fe;
	box-shadow: 0px 0px 2px rgba(92, 129, 234, 0.55);
}
