@import '../../../../constants/_mixins.scss';


.BottomButtons {
  display: flex;
  gap:15px;
  width: calc(100% - 32px);
  margin: 32px 16px;
  justify-content: center;  

  @include xsmobile {
    display: flex;
    margin-top: 0;
  }

  @include mobile {
    display: flex;
    margin-top: 0;
  }
}

.IgnoreMarginTop {
  margin-top: 32px;
}

.BottomButtons button{
  width: 100%;
}

.BottomButtons .ButtonShort {
  width: 32%;
}

.BottomButtons .ButtonLeft {
  justify-content: left;
}

