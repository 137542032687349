.Header {
	margin: 8px 0 24px 16px;
	display: flex;
	gap: 10px;
}

.Title {
	margin-bottom: 5px;
	font-family: Metropolis;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
}

.SubTitle {
	font-family: 'Lato';
	font-size: 16px;
	line-height: 19px;
	color: #757575;
}

.Back {
	cursor: pointer;
}
