.Invalid .Line {
	background: #ff424d;
}

.Invalid .InputTitle {
	color: #ff424d;
}

.Line {
	width: 100%;
	height: 1px;
	background: #dadada;
}

.InputTitle {
	font-size: 16px;
	color: #000000;
}

.Input input {
	border: 0;
	font-family: 'Lato';
	font-size: 16px;
	font-weight: 400;
	margin: 8px 0;
	width: 97%;
	height: 22px;
	padding: 0;
}

.Input input:focus {
	outline: black;
	border: 1px solid black;
	border-radius: 3px;
}

.Input {
	margin: 18px 0;
}

@-moz-document url-prefix() {
	.Input {
		margin: 17px 1.5px;
	}
}
