@import '../../../../constants/mixins';

.CardText {
  color: #757575;
  position: absolute;
  left: 34px;
  bottom: 46px;

  @include xsmobile {
    left: 25px;
    bottom: 56px;
  }
}

.Last4Digits {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;

  & p {
    margin: 0;
  }

  & .Dots {
    font-size: 10px;
    margin: 0 1px;

    &.HideS {
      @include mobile() {
        margin: 10px 0;
        display: none;
      }
    }

    &.HideXS {
      @include xsmobile() {
        margin: 10px 0;
        display: none;
      }
    }
  }

  & .Last {
    margin: 0 4px;
  }

  & .Routing {
    margin-right: 10px;
  }
}

.ECheck {
  background-image: url('../../../../assets/e-check-info.svg');
  background-size: 100% 100%;
  background-position: center;
  margin: 5px;
  margin-top: 12px;
  padding: 10px;
  width: calc(100% - 30px);
  cursor: pointer;
  border-radius: 10px;
  min-width: 170px;
  min-height: 146px;
  position: relative;
}
