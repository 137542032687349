.PaymentCardText {
	font-family: 'Lato';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	display: inline-block;
	line-height: 40px;
	vertical-align: top;
	padding-left: 12px;
}

.PaymentCardBody {
	display: flex;
}

.PaymentCardECheckText {
	display: inline-block;
	font-family: 'Lato';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 40px;
	padding-left: 12px;
}

.PaymentCardLogo {
	height: 40px;
	width: 60px;
	display: inline-block;
}

.DeleteBody {
	margin-bottom: 50px;
}
