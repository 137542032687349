.Checkbox {
	display: block;
	position: relative;
	padding-left: 25px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: inline-block;
}

.Checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.Checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 16px;
	width: 16px;
	background: #ffffff;
	border: 1px solid #bebebe;
	border-radius: 2px;
}

.Checkbox input:checked ~ .Checkmark {
	background: #5c81ea;
	border: 1px solid #5c81ea;
}

.Checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

.Checkbox input:checked ~ .Checkmark:after {
	display: block;
}

.Checkbox .Checkmark:after {
	left: 6px;
	top: 2px;
	width: 3px;
	height: 7px;
	border: solid #ffffff;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.Checkmark {
	margin-top: 1px;
}

.CheckboxInput {
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: center;
	width: 100%;
}
