@import '../../../../../constants/mixins';

.ECheckInputs {
  margin: 0px 16px;
}

.InputsWrapper {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;

  @include xsmobile() {
    flex-wrap: wrap;
  }
}

.InputsWrapper > div {
  margin-left: 8px;
  margin-right: 8px;

  @include xsmobile() {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.InputsWrapper > div:first-child {
  margin-left: 0px;
}

.InputsWrapper > div:last-child {
  margin-right: 0px;
}

.InfoWrapper {
  display: flex;
  align-items: center;
  height: 48px;
  justify-content: center;

  & > svg {
    margin: 0 8px 0 0;
  }

  & span {
    font-family: Lato;
    font-size: 16px;
    line-height: 19px;
    color: #a5a5a5;
    margin: 0;
    margin-bottom: 1px;
  }
}

.ECheckInfoIconWrapper {
  display: flex;
  justify-content: center;
}

.ECheckInfoIcon {
  margin: 8px;
}

.ECheckAuthReccuringPayment {
  background: inherit;
  padding: 5px;
  margin: 16px 'auto';
}

.ECheckAuthReccuringPaymentDesc {
  box-shadow: 0px 0px 2px rgba(92, 129, 234, 0.3);
  border-radius: 15px;
  padding: 10px;
  text-align: justify;
  text-justify: inter-word;
}
