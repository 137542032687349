@media (max-width: 480px) {
	.FlexContainer {
		display: flex;
		flex-flow: column;
		height: 100%;
	}
}

.PaymentHeader {
	background-color: #5c81ea;
	width: 100%;
	display: inline-block;
	flex: 0 1 auto;
}

.Logo {
	float: left;
	padding: 13.5px 15px;
	height: 28;
}

.PaymentStep {
	float: right;
	color: white;
	padding: 17.5px 15px;
	text-align: right;
	margin: 0;
}

@media (min-width: 768px) {
	.PaymentMain {
		width: 446px;
	}
}

@media (min-width: 481px) and (max-width: 767px) {
	.PaymentMain {
		width: 406px;
	}
}

@media (min-width: 481px) {
	.PaymentMain {
		background: #ffffff;
		box-shadow: 0px 0px 10px rgba(92, 129, 234, 0.3);
		border-radius: 30px;
		padding: 34px;
		margin: 16px auto;
	}
}

.PaymentMain {
	height: 100%;
	flex: 1 1 auto;
	display: flex;
	flex-flow: column;
}
