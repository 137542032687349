.PaymentDetails {
  margin: 5vh 7%;
  background: #ffffff;
  border: 1px solid #ced9f9;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 23px 26px;
  background-image: url('../../../assets/ellipse_2.svg');
  background-size: 100% 100%;
}

@media (max-height: 550px) {
  .PaymentDetails {
    margin: 15px 7%;
  }
}

.PaymentCardText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: inline-block;
  line-height: 40px;
  vertical-align: top;
  padding-left: 12px;
}

.PaymentCardECheckText {
  display: inline-block;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  padding-left: 12px;

  & * {
    display: block;
    height: 20px;
  }
}

.PaymentCardLogo {
  height: 40px;
  width: 60px;
  display: inline-block;
}

.PaymentTotalText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin: 13px 0;
  display: flex;
  align-items: center;
  color: rgba(92, 129, 234, 0.5);
  display: inline-block;
  margin-right: 10px;
}

.PaymentTotalCurrencySign {
  font-family: Metropolis;
  font-size: 24px;
  display: inline-block;
  color: #5c81ea;
}

.PaymentTotalPrice {
  font-family: Metropolis;
  font-size: 24px;
  color: #5c81ea;
  margin: 11px 0 0 0;
  display: inline-block;
}

.PaymentLine {
  background: rgba(92, 129, 234, 0.3);
  height: 1px;
  margin-top: 24px;
  margin-bottom: 11px;
}

@media (min-width: 481px) {
  .PaymentDetails {
    margin: 60px 7%;
  }
}
