.Header {
	margin: 37px 13%;
}

.Checkmark {
	display: inline-block;
	width: 76px;
	height: 76px;
	border: 2px solid #5c81ea;
	border-radius: 50%;
	display: inline-block;
	background-image: url('../../../assets/checkmark_1.svg');
	background-repeat: no-repeat;
	background-position: center;
}

.Success {
	margin-left: 16px;
	width: calc(100% - 96px);
	display: inline-block;
	vertical-align: top;
}

.SuccessHeader {
	margin: 0;
}

.SuccessText {
	margin: 0;
	margin-top: 10px;
}
