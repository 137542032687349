@import '../../../../../constants/mixins';

.CreditCardBox {
  margin: 15px 14%;
  height: 150px;
}

.CreditCardInputs {
  margin: 0px 16px;
}

.NameInput {
  width: 100%;
}

.InputsWrapper {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;

  @include xsmobile() {
    flex-wrap: wrap;
  }
}

.InputsWrapper > div {
  margin-left: 8px;
  margin-right: 8px;

  @include xsmobile() {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.InputsWrapper > div:first-child {
  margin-left: 0px;
}

.InputsWrapper > div:last-child {
  margin-right: 0px;
}

.ZipCodeInput {
  width: 35%;
  @include xsmobile() {
    width: 100%;
  }
}

.CcexpInput {
  width: 35%;
  @include xsmobile() {
    width: 100%;
  }
}

.CcvInput {
  width: 20%;
  @include xsmobile() {
    width: 100%;
  }
}

.HiddenField {
  display: none;
}
