@mixin desktop() {
  @media (min-width: 1680px) {
    @content;
  }
}

@mixin laptop() {
  @media (min-width: 1025px) and (max-width: 1679px) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 641px) and (max-width: 1024px) {
    @content;
  }
}

@mixin small() {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin mobile() {
  @media (min-width: 371px) and (max-width: 480px) {
    @content;
  }
}

@mixin xsmobile() {
  @media only screen and (max-width: 370px) {
    @content;
  }
}


