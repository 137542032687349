.Grow {
	transition: all 0.2s ease-in-out;
	transform: scale(1.1);
}

.Shrink {
	transition: all 0.2s ease-in-out;
	transform: scale(0.9);
}

.Option {
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(92, 129, 234, 0.45);
	border-radius: 10px;
	height: 56px;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #5c81ea;
	cursor: pointer;
}

.Option * {
	margin: 0 10px;
}

.Slider {
	margin-bottom: 50px;
}
