.Button {
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(92, 129, 234, 0.45);
	border-radius: 10px;
	height: 154px;
	margin: 15px;
	display: flex;
	cursor: pointer;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	transition: all 0.2s ease-in-out;
	min-width: 170px;
	min-height: 146px;
}

.Button * {
	cursor: pointer;
}

.Grow {
	transition: all 0.2s ease-in-out;
	transform: scale(1.1);
}

.Shrink {
	transition: all 0.2s ease-in-out;
	transform: scale(0.9);
}

.Label {
	margin-top: 20px;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #5c81ea;
	margin-bottom: 30px;
}
