.Backdrop {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	background-color: rgba(255, 255, 255, 0.75);
}

.Loader {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 500;
	left: 0;
	top: 0;
}
