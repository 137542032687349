.PaymentMethodButton {
	width: calc(100% - 32px);
	margin: 15px 16px 15px 16px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 0px 4px rgba(92, 129, 234, 0.45);
	border: 0;
}

.PaymentMethodButton * {
	margin: 0 10px;
}

.GooglePayPaymentMethodWrapper {
	margin: 15px 15px 15px 15px;
}

.GooglePayPaymentMethodButton {
	width: calc(100% - 32px);
	margin: 15px 16px 15px 16px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 0px 4px rgba(92, 129, 234, 0.45);
	border: 0;
	padding: 0px;
}
