@import '../../../../constants/mixins';

.Logo {
  margin: 25px 0 25px 15px;
  width: 60px;
  height: 35px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  background-size: contain;
}

.CardText {
  margin: 20px 0 20px 12px;
  display: inline-block;
  vertical-align: top;
  color: #ffffff;
}

.Last4Digits {
  display: flex;
  align-items: center;
  margin: 0;

  @include xsmobile() {
    font-size: 14px;
  }

  & p {
    margin: 0;
  }

  & .Dots {
    font-size: 12px;
    margin: 0 2px;

    &.Margin {
      margin-left: 10px;
    }

    &.HideS {
      @include mobile() {
        margin: 10px 0;
        display: none;
      }
    }

    &.HideXS {
      @include xsmobile() {
        margin: 10px 0;
        display: none;
      }
    }
  }

  & .Last {
    margin: 0 8px;
  }
}

.Remove {
  vertical-align: middle;
  text-align: center;
  float: right;
  margin: 23px 15px;
  cursor: pointer;
  z-index: 2;
}

.CreditCard {
  margin: 15px;
  width: calc(100% - 30px);
  cursor: pointer;
  background: linear-gradient(180deg, #2554d9 0%, #82a3ff 100%);
  border: 1px #2554d9 solid;
  box-shadow: 0px 0px 4px rgba(92, 129, 234, 0.45);
  border-radius: 10px;
  font-family: Lato;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-width: 170px;
  min-height: 146px;
}

.CreditCard:hover {
  box-shadow: 0px 0px 6px rgba(92, 129, 234, 0.35);
}

.CreditCard:active {
  box-shadow: 0px 0px 2px rgba(92, 129, 234, 0.55);
}
