.Header {
	margin: 0 10%;
	padding: 73px 0;
}

.Ellipse {
	background-image: url('../../../assets/ellipse_3.svg');
	background-repeat: no-repeat;
	background-position: auto;
	background-size: 100%;
	width: 100%;
}

.X {
	display: inline-block;
	width: 76px;
	height: 76px;
	border: 2px solid #f55151;
	border-radius: 50%;
	display: inline-block;
	background-image: url('../../../assets/x_1.svg');
	background-repeat: no-repeat;
	background-position: center;
}

.Declined {
	margin-left: 16px;
	width: calc(100% - 96px);
	display: inline-block;
	vertical-align: top;
}

.DeclinedHeader {
	margin: 0;
}

.DeclinedText {
	margin: 0;
	margin-top: 10px;
}

.CloseButton {
	width: calc(32% - 22.5px);
	height: 42px;
	display: inline-block;
	margin: 0 7.5px 0 15px;
}

.TryAgainButton {
	width: calc(68% - 22.5px);
	height: 42px;
	display: inline-block;
	margin: 0 15px 0 7.5px;
}

@media (min-width: 481px) {
	.Ellipse {
		position: relative;
		margin: -34px;
		padding: 34px;
		background-image: url('../../../assets/ellipse_4.svg');
		background-repeat: no-repeat;
		background-size: cover;
		background-clip: border-box;
	}
}
