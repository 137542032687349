.Backdrop {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.5);
}

.Modal {
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	margin: 45% 7% 10% 7%;
	position: fixed;
	z-index: 500;
	width: 86%;
}

.ModalTitle {
	background: rgba(245, 81, 81, 0.12);
	color: #f55151;
	padding: 25px;
	margin: 0;
}

.ModalMessage {
	padding: 25px;
}

.OkButton {
	width: calc(42% - 22.5px);
	height: 42px;
	margin: 23px 25px 25px 0px;
	float: right;
}

@media (min-width: 481px) {
	.Modal {
		left: 50%;
		width: 384px;
		margin-left: -192px;
		margin-top: 40vh;
	}
}
